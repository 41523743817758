<template>
   <v-form ref="order-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Order detail</h3>
         </v-col>
         <v-col cols="12">
            <v-btn color="secondary" class="m-auto px-8" large @click.prevent="$router.go(-1)" :disabled="isFetching">Back</v-btn>
         </v-col>

         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="12" cols="12">
                        <FormControl inputType="select" v-model="data.order_status" label="Status" :options="dataOptions.orderStatus" :required="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>

         <v-col cols="12">
            <v-card>
               <v-card-title><h5>Infomation</h5></v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.create_date" label="Create date" :options="dataOptions.orderStatus" :readonly="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.user" label="User" :readonly="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.package" label="Package" :readonly="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.category" label="Category" :readonly="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="number" v-model="data.price" label="Price" :options="dataOptions.packageList" :readonly="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>

         <v-col md="12" cols="12">
            <v-card>
               <v-card-title><h5>Payment</h5></v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col md="12" cols="12">
                        <FormControl inputType="select" v-model="data.payment.method" label="Method" :options="dataOptions.paymentMethod" :readonly="true"/>
                     </v-col>
                  </v-row>
                  <v-row v-if="data.payment.method === 'offline'">
                     <v-col md="6" cols="12">
                        <ImageBtnUpload inputType="string" v-model="data.payment.photo" label="Photo" :readonly="true"/>
                     </v-col>
                  </v-row>
                  <v-row v-else>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.payment.cardNumber" label="Card number" :options="dataOptions.packageList" :readonly="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.payment.name" label="Name" :options="dataOptions.packageList" :readonly="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>

         <v-col cols="12">
            <v-row no-gutters>
               <v-btn color="primary" class="form-btn ma-2 m-auto px-8" large :disabled="isFetching" type="submit">Save</v-btn>
            </v-row>
         </v-col>
         <!-- <v-col cols="12">
            <v-btn color="primary" class="px-8 form-btn" large :disabled="isFetching" type="submit">Save</v-btn>
            <FormDeleteBtn :removeAction="removeItem" />
         </v-col> -->
      </v-row>
   </v-form>
</template>

<script>
import FormButtonUpload from '@/components/form/FormButtonUpload'
import FormControl from '@/components/form/FormControl'
import FormDisplay from '@/components/form/FormDisplay'
import ImageBtnUpload from '@/components/form/ImageBtnUpload'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
   name: 'OrderManagementDetail',
   components: {
      FormControl,
      FormDisplay,
      FormButtonUpload,
      ImageBtnUpload,
   },
   data() {
      return {
         data: {
            id: _.toNumber(this.$route.params.id),
            create_date: '',
            order_status: '',
            category: 0,
            package: 0,
            user: 0,
            price: 0,
            payment: {
               method: '',
               // offline
               photo: '',
               // credit card
               cardNumber: '',
               name: '',
            }
         },
         dataOptions: {
            orderStatus: [
               { value: 'pending', text: 'Pending' },
               { value: 'paid', text: 'Paid' },
               { value: 'rejected', text: 'Rejected' },
            ],
            paymentMethod: [
               { value: 'offline', text: 'Offline / FPS' },
               { value: 'creditCard', text: 'Credit card' },
            ]
         },
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest', 'showLoader'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),
      
      async initData() {
         this.showLoader(true)
         const resp = await this.sendRequest({ type: 'get_order_by_id', data: { id: this.data.id }, options: { noStatusSet: true }})
         if (!resp) {
            this.showLoader(false)
            return
         }
         const [userResp, packageResp, categoryResp] = await Promise.all([
            this.sendRequest({ type: 'get_user_by_id', data: { id: resp.data.user_id }, options: { noStatusSet: true }}),
            this.sendRequest({ type: 'get_voucher_package_by_id', data: { id: resp.data.package_id }, options: { noStatusSet: true }}),
            this.sendRequest({ type: 'get_voucher_category_by_id', data: { id: resp.data.category_id }, options: { noStatusSet: true }}),
         ])
         this.showLoader(false)
         this.data = {
            ...this.data,
            create_date: moment(resp.data.create_date).format('YYYY-MM-DD HH:mm:ss'),
            order_status: resp.data.order_status,
            user: userResp.data?.username,
            package: packageResp.data?.title,
            category: categoryResp.data?.title,
            price: resp.data.price,
            payment_method: resp.data.payment_method,
         }
         if (typeof resp.data.payment_method === 'string') {
            // offline
            this.data = {
               ...this.data,
               payment: {
                  method: 'offline',
                  photo: resp.data.payment_method,
               },
            }
         } else {
            // creditCard
            this.data = {
               ...this.data,
               payment: {
                  method: 'creditCard',
                  cardNumber: resp.data.payment_method.card_number,
                  name: resp.data.payment_method.name,
               },
            }
         }
      },
      async formSubmit() {
         console.log('save')
         const isValid = this.$refs['order-form'].validate()
         if (isValid) {
            const resp = await this.updateItem()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp, 
               returnLink: (this.data.id === undefined ? { name: 'orderManagement' } : null )
            })
            this.setShowDialog(true)
         }
      },
      async updateItem() {
         console.log('update')
         const formdata = {
            data_type: 'order_data',
            id: this.data.id,
            order_status: this.data.order_status,
            // category_id: this.data.category,
            // package_id: this.data.package,
            // user_id: this.data.user,
            // price: this.data.price,
            // payment_method: this.data.payment_method,
         }
         return await this.sendRequest({ type: 'update_order', data: formdata })
      },
   },
   async mounted() {
      await this.initData()
   }
}
</script>
